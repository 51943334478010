import { render, staticRenderFns } from "./backend-material.vue?vue&type=template&id=49e7abae&"
import script from "./backend-material.ts?vue&type=script&lang=ts&"
export * from "./backend-material.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports